import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import { usePageContext } from "../Context/pageContext";

export default function GoHome() {
  const { lang } = usePageContext();
  const { settings } = useStaticQuery(query);
  return (
    <Link to="/" className="homeButton">
      {settings.homeButton[lang]}
    </Link>
  );
}

const query = graphql`
  query {
    settings: sanitySiteSettings {
      homeButton {
        en
        es
      }
    }
  }
`;
