import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import * as styles from "../styles/terms.module.scss";
import { usePageContext } from "../components/Context/pageContext";
import BlockContent from "@sanity/block-content-to-react";
import { BurgerMenu } from "../components/Header/burgerMenu";
import GoBack from "../components/Utils/backButton";
import GoHome from "../components/Utils/homeButton";
import SEO from "../components/seo";

interface Props {
  data: any;
}

const Terms: React.FC<Props> = ({ data }) => {
  const { terms } = data;
  const { lang } = usePageContext();
  const raw = "_raw" + lang.charAt(0).toUpperCase() + lang.slice(1);

  const handleClick = () => {
    const element = document.getElementById("navvMenu");
    if (element) element.classList.toggle("inactive");
  };

  return (
    <Layout theme={"light"} withoutLogo={true}>
      <SEO
        title={data?.seo?.legals?.title && data?.seo?.legals?.title[lang]}
        description={data?.seo?.legals?.desc && data?.seo?.legals?.desc[lang]}
        image={data?.seo?.legals?.image?.asset?.url}
      />
      <div className={`margin ${styles.pageWrapper}`}>
        <div className={styles.mobileTitle}>
          <GoHome />
          <div className={styles.pageTitle}>{terms.title[lang]}</div>
          <div id="burgerMenu" className={styles.burgerPosition}>
            <BurgerMenu theme={"light"} onClick={handleClick} />
          </div>
        </div>

        <GoBack />
        <div className={styles.pageTitle}>{terms.title[lang]}</div>
        <div className={styles.termsContent}>
          {terms.articles.map((article: any, index: number) => {
            return (
              <div key={`article-${index}`} style={{ marginBottom: "30px" }}>
                <div className={styles.articleTitle}>{article.title[lang]}</div>
                <div className={styles.articleContent}>
                  <BlockContent blocks={article.content[raw]} />
                </div>
              </div>
            );
          })}
          {/* </div> */}
        </div>
      </div>
    </Layout>
  );
};

export default Terms;

export const query = graphql`
  query {
    terms: sanityTerm {
      title {
        en
        es
      }
      slug
      articles: termArticles {
        content {
          _rawEn
          _rawEs
        }
        title {
          en
          es
        }
      }
    }
    seo: sanitySiteSettings {
      legals {
        desc: seoDescription {
          en
          es
        }
        image: seoImage {
          asset {
            url
          }
        }
        title: seoTitle {
          en
          es
        }
      }
    }
  }
`;
