import React from "react";
import { graphql, navigate, useStaticQuery } from "gatsby";
import { usePageContext } from "../Context/pageContext";

export default function GoBack() {
  const { lang } = usePageContext();
  const { settings } = useStaticQuery(query);
  return (
    <button
      onClick={(e) => {
        e.stopPropagation();
        navigate(-1);
      }}
      className="backButton"
    >
      {settings.backButton[lang]}
    </button>
  );
}

const query = graphql`
  query {
    settings: sanitySiteSettings {
      backButton {
        en
        es
      }
    }
  }
`;
